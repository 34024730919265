import styled from 'styled-components';
import {
  desktop_breakpoint,
  desktop_breakpoint_extended,
  sm_desktop_breakpoint,
} from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';
import Colors from 'pubweb-smokey/dist/colors.js';

export const imageAspectRatios = {
  hd: { width: 1800, height: 870 },
  desktop: { width: 1438, height: 735 },
  mobile: { width: 768, height: 650 },
};

export const HeroImageStyles = styled.div`
  width: 100%;

  .error {
    background-color: ${Colors.accent.barnDoor.standard};
    color: #ffffff;
    font-family: 'source-sans-pro', sans-serif;
    font-size: 12px;
    margin-top: 5px;
    padding-left: 12px;
    width: 100%;
  }
  .hero-content {
    margin: auto;
    max-width: 500px;
    width: 86%;
  }

  .hero-image {
    position: relative;
    .aspect-holder {
      min-height: 550px;
    }
    max-height: ${(props) =>
      props.$isMobileLayout ? 'none' : imageAspectRatios.mobile.height + 'px'};

    img {
      max-width: initial;
    }
  }

  form {
    display: flex;
    flex-direction: row;

    input {
      height: 55px;
    }
    .suggestion-box.open .dropdown-menu {
      top: 37px;
    }
    .compact .suggestion-clear,
    .compact .dropdown-chevron {
      top: 20px;
    }
    .hero-location-button {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      font-size: 0;

      * {
        pointer-events: none;
      }

      svg {
        height: 24px;
        margin-top: -5px;
        pointer-events: none;
        width: 24px;
        top: 3px;

        * {
          fill: ${(props) => props.$svgColor};
        }
      }
    }

    input {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Ctitle%3ELocation Marker Place Line%3C/title%3E%3Cpath fill='%2324292D' fill-rule='nonzero' d='M11.54 12.95a.5.5 0 1 1 0 1H4.542a.5.5 0 1 1 0-1zM8.041 2c3.106 0 5.048 3.363 3.465 6.1l-3.063 4.16a.5.5 0 0 1-.805 0L4.545 8.054C2.993 5.363 4.935 2 8.041 2zm0 1c-2.338 0-3.798 2.53-2.66 4.508l2.66 3.612 2.63-3.566C11.838 5.53 10.378 3 8.041 3zm0 1.562a1.585 1.585 0 1 1-.001 3.17 1.585 1.585 0 0 1 0-3.17zm0 1a.585.585 0 1 0 0 1.17.585.585 0 0 0 0-1.17z'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: 9px center;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      padding-left: 30px;
    }
  }

  .hero-text {
    margin-bottom: 40px;
    text-align: center;
    text-shadow: rgba(0, 0, 0, 0.75) 0px 2px 4px;

    .large-heading {
      font-size: 40px;
      line-height: 48px;
      letter-spacing: -0.35px;
      color: #ffffff;
      font-family: 'source-sans-pro', Helvetica, Arial, sans-serif;
      font-weight: 400;
    }
  }

  @media screen and (max-width: 360px) {
    .hero-content .large-heading {
      font-size: 36px;
    }
  }

  @media screen and (min-width: ${desktop_breakpoint}px) {
    .hero-text {
      .large-heading {
        font-size: 60px;
        font-weight: 700;
        line-height: 68px;
        letter-spacing: -0.525px;
        font-family: 'acumin-pro', sans-serif;
      }
    }
  }

  .hero-content,
  .hero-content h5 {
    color: ${Colors.primary.white.standard};
  }

  .hero-content {
    margin: auto;
  }

  .hero-content-container {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    .hero-content {
      padding-top: 75px;
    }

    .city-location-suggestion {
      width: 100%;
      .error-message {
        background-color: ${Colors.accent.barnDoor.standard};
        color: ${Colors.primary.white.standard};
        margin-top: 5px;
        position: absolute;
      }
    }
  }

  .hero-model-link {
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    bottom: 10px;
    padding: 0 10px;
    position: absolute;
    right: 10px;

    a:link,
    a:visited,
    a:hover,
    a:active {
      color: ${Colors.primary.white.standard};
      display: flex;
      flex-direction: row;
      font-family: 'source-sans-pro', Helvetica, Arial, sans-serif;
      font-size: 12px;
      text-decoration: none;
    }

    svg {
      margin: 5px -10px -4px 10px;

      * {
        fill: ${Colors.primary.white.standard};
      }
    }
  }

  a.transparent,
  button.transparent {
    border-color: #ffffff !important; /* !important is here to override a browser quirk with transparent border colors */
  }

  @media screen and (min-width: ${desktop_breakpoint_extended}px) {
    .error {
      width: 400px;
    }

    .zipcode-error {
      width: 400px;
    }

    .hero-content {
      max-width: none;
    }

    .hero-content-container .hero-content {
      padding-top: 10%;
    }

    .hero-image {
      max-height: ${imageAspectRatios.desktop.height}px;
    }

    .hero-text {
      text-align: left;
      width: 500px;
    }

    form {
      width: 400px;

      .hero-location-button {
        font-size: 14px;
        white-space: nowrap;

        span {
          font-family: 'source-sans-pro', Helvetica, Arial, sans-serif;
        }

        svg {
          display: none;
        }
      }
    }
  }

  @media screen and (min-width: ${sm_desktop_breakpoint}px) {
    .hero-image {
      img {
        transform: translate(-50%, -65%);
      }
    }
  }

  @media screen and (min-width: 2000px) {
    margin-bottom: 240px;
    .hero-model-link {
      bottom: -230px;
    }
  }
`;
