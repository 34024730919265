import styled from 'styled-components';
import {
  desktop_breakpoint,
  desktop_container_maxwidth,
} from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';
import Colors from 'pubweb-smokey/dist/colors';

export const SlideshowStyles = styled.div`
  position: relative;

  .tour-button-container {
    position: absolute;
    bottom: -45px;

    button.inverse {
      background-color: #fff;
    }
  }

  .modal-container {
    width: 90%;
    height: 90%;

    .modal-content {
      height: 100%;
      text-align: center;
    }
    iframe {
      width: 90%;
      height: 100%;
      margin: auto;
    }
  }
  .slider-wrapper {
    .slide {
      background-color: ${Colors.secondary.grey6.standard};
      line-height: 0;

      img {
        pointer-events: all;
        max-height: ${(props) =>
          props.$viewportWidth < desktop_container_maxwidth
            ? props.$viewportWidth / 1.75 + 'px'
            : '730px'};
        object-fit: contain;
      }
    }
  }
  .carousel {
    &.carousel-slider {
      overflow: visible;

      .control-arrow,
      .control-arrow:hover {
        width: 55px;
        height: 55px;
        background-color: #fff;
        border-radius: 50%;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
        opacity: 1;
        top: 40%;
        display: block;

        &:before {
          border: none;
          background-size: cover;
          content: ' ';
          position: relative;
          display: inline-block;
          width: 20px;
          height: 20px;
          top: 3px;
        }
      }
      .control-prev {
        left: -2.5%;

        &:before {
          background-image: url('/public-assets/images/chevron-lft.svg');
          left: 3px;
        }
      }
      .control-next {
        right: -2.5%;

        &:before {
          background-image: url('/public-assets/images/chevron-right.svg');
          left: 8px;
        }
      }
    }

    .thumbs-wrapper {
      margin: 10px 0;

      .control-arrow {
        background-color: ${Colors.accent.grey1.standard};
        width: 25px;
        height: 25px;
        margin-top: -15px;
        opacity: 0.8;
      }

      .thumb {
        border: none;
        max-height: 80px;
        padding: 0;
        cursor: pointer;

        &.selected {
          border: solid 2px black;
        }
      }
    }
  }

  @media only screen and (min-width: ${desktop_breakpoint}px) {
    position: relative;

    .tour-button-container {
      position: absolute;
      top: 20px;
      left: 20px;
    }

    .carousel {
      .slide {
        img {
          width: 100%;
        }
      }
      &.carousel-slider {
        overflow: visible;

        .control-arrow,
        .control-arrow:hover {
          /* !important is used here because react-responsive-carousel loves control */
          display: ${(props) =>
            props.$showArrows ? 'display !important' : 'none !important'};
          width: 55px;
          height: 55px;
          background-color: #fff;
          border-radius: 50%;
          box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
          opacity: 1;
          top: 47%;
          display: block;

          &:before {
            border: none;
            background-size: cover;
            content: ' ';
            position: relative;
            display: inline-block;
            width: 20px;
            height: 20px;
            top: 3px;
          }
        }
        .control-prev {
          left: -2.5%;

          &:before {
            background-image: url('/public-assets/images/chevron-lft.svg');
            left: 3px;
          }
        }
        .control-next {
          right: -2.5%;

          &:before {
            background-image: url('/public-assets/images/chevron-right.svg');
            left: 8px;
          }
        }
        .control-dots {
          z-index: 9999;
        }
      }
    }
  }
`;
