import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext } from 'react';
import Link from 'next/link';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import * as variables from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';
import { runCommonValidation } from 'pubweb-smokey/dist/utils/validation';
import Button from '@components/Shared/Buttons/Button';
import MagnifyingGlassSvg from 'pubweb-smokey/dist/images/svg/iconography-16x16/magnifying-glass.svg';
import useWindowResize from 'pubweb-smokey/dist/hooks/useWindowResize';
import AspectImage from '@components/Shared/AspectImage/AspectImage';
import Form from '@components/Shared/Form/Form';
import { modelNameTransformer } from 'pubweb-smokey/dist/utils/modelNameTransformer';
import ChevronRightSvg from 'pubweb-smokey/dist/images/svg/iconography-16x16/chevron-right.svg';
import Colors from 'pubweb-smokey/dist/colors.js';
import { pushGTMEvent } from 'pubweb-smokey/dist/utils/analytics';

import { getModelByModelNumber } from '@services/homesService';
import { convertButtonColor, reverseColors } from '@utils/ctaUtils';
import { extractColors, formatHttpsUrl } from '@utils/utils';

import { HeroImageStyles, imageAspectRatios } from './HeroImage.styled';
import { LocationsContext } from '@contexts/LocationsContext';
import { getLocationByIpOrPostalCode } from '@services/locationService';

const CityLocationSuggestion = dynamic(
  () => import('@components/Shared/CityLocationList/CityLocationSuggestion'),
  {
    ssr: false,
  }
);

const HeroImage = ({
  heroImage,
  mobileHeroImage,
  heroCTA,
  heroIndex,
  isMobileLayout,
}) => {
  const [zipError, setZipError] = useState({});
  const [modelNumber, setModelNumber] = useState();
  const [modelInfo, setModelInfo] = useState();
  const [altText, setAltText] = useState('');
  const [heroImages] = useState({
    hd:
      heroImage?.imageAsset.file.url +
      '?fm=webp&q=100&w=' +
      imageAspectRatios.hd.width,
    desktop:
      heroImage?.imageAsset.file.url +
      '?fm=webp&q=100&w=' +
      imageAspectRatios.desktop.width,
    mobile:
      mobileHeroImage?.imageAsset.file.url +
      '?fm=webp&q=100&w=' +
      imageAspectRatios.mobile.width,
  });

  const locationsContext = useContext(LocationsContext);
  const router = useRouter();

  useWindowResize(() => {
    if (window.innerWidth >= variables.desktop_breakpoint_extended) {
      setModelNumber(heroImage?.modelNumber);
      setAltText(heroImage?.altText);
    } else {
      setModelNumber(mobileHeroImage?.modelNumber);
      setAltText(mobileHeroImage?.altText);
    }
  }, [variables.desktop_breakpoint_extended]);

  useEffect(() => {
    if (modelNumber) {
      getModelByModelNumber(modelNumber).then((result) => setModelInfo(result));
    } else {
      setModelInfo(null);
    }
  }, [modelNumber]);

  useEffect(() => {
    if (
      locationsContext.state.isLocationCallCompleted &&
      (!locationsContext.state || !locationsContext.state.postalCode)
    ) {
      setZipError(
        runCommonValidation({
          zip: {
            value: ' ',
            touched: true,
            notRequired: false,
          },
        })
      );
    } else if (
      locationsContext.state.isLocationCallCompleted &&
      locationsContext.state.postalCode
    ) {
      setZipError(
        runCommonValidation({
          zip: {
            value: locationsContext.state.postalCode,
            touched: true,
            notRequired: false,
          },
        })
      );
    }
  }, [locationsContext.state]);

  const buttonColors = extractColors(heroCTA?.buttonAndTextColors);

  // Validating in the submit method for a couple of reasons.
  // The onFormValidate and onSubmit were both executing when submitting.
  // We need to check the zip code for a valid geocode location before updating the context and navigating to find-a-home. The flow was easier in the handleSubmit.
  const handleSubmit = (formData) => {
    // Do common zip code value validation first.
    let formErrors = runCommonValidation({
      zip: {
        value: formData?.zip ? formData.zip : '',
        touched: true,
        notRequired: false,
      },
    });

    // If no common errors, check for valid location using zip code.
    if (formData.zip && !formErrors.hasErrors) {
      getLocationByIpOrPostalCode(formData.zip)
        .then(() => {
          // Successful entry, update the context.
          locationsContext.actions.updatePostalCode(formData.zip);
          pushGTMEvent('homepage-find-homes');
          router.push('/find-a-home/');
        })
        .catch(() => {
          // Could not get a location for the zip code.
          formErrors.zip =
            'The zip code you entered is not valid. Please try again.';
          formErrors.hasErrors = true;
          setZipError(formErrors);
        });
    } else {
      // Save common errors so they can be displayed.
      setZipError(formErrors);
    }
  };

  return (
    <HeroImageStyles
      $svgColor={buttonColors[1]}
      $isMobileLayout={isMobileLayout}
    >
      <div className="hero-image">
        <AspectImage
          key={`aspect-image-${heroIndex}`}
          behavior="fill-container"
          imageOrientation="landscape"
          containerOrientation="landscape"
          alt={altText}
          srcset={[
            {
              src: formatHttpsUrl(heroImages.hd),
              mediaWidth: `${variables.lg_desktop_breakpoint}`,
              aspectRatio: imageAspectRatios.hd,
              imgOrientation: 'landscape',
            },
            {
              src: formatHttpsUrl(heroImages.desktop),
              mediaWidth: `${variables.desktop_breakpoint}`,
              aspectRatio: imageAspectRatios.desktop,
              imgOrientation: 'landscape',
            },
            {
              src: formatHttpsUrl(heroImages.mobile),
              aspectRatio: imageAspectRatios.mobile,
              imgOrientation: 'landscape',
            },
          ]}
        />
        <div className="hero-content-container">
          <div className="hero-content">
            <div className="hero-text">
              <h5>{heroCTA?.ctaCustomCategory}</h5>
              <h1 className="large-heading">{heroCTA?.ctaHeader}</h1>
            </div>
            <Form onSubmit={handleSubmit}>
              <CityLocationSuggestion
                id="zip"
                zipCode={locationsContext.state.postalCode}
                dropDownStyle="compact"
                label="Enter city, state or ZIP Code "
              />
              <Button
                primaryColor={buttonColors && convertButtonColor(buttonColors)}
                buttonStyle={
                  buttonColors &&
                  convertButtonColor(buttonColors) === Colors.primary.white
                    ? 'outlined'
                    : null
                }
                onDarkBg={buttonColors && reverseColors(buttonColors)}
                className="gtm-homes-search hero-location-button"
                automationId="hero-location-button"
              >
                {heroCTA?.firstCtaButtonText}
                <MagnifyingGlassSvg viewBox="0 0 16 16" />
              </Button>
            </Form>
            {zipError.zip && <p className="error">{zipError.zip}</p>}
          </div>
        </div>
        {modelInfo && (
          <div
            className="hero-model-link"
            onClick={() => {
              pushGTMEvent(
                'ch.headerHomeDetailsCTAClick',
                'header_home_click',
                {
                  location: 'Homepage',
                  category: 'home_consideration',
                  action: 'button',
                  label: 'ch.headerHomeDetailsCTAClick',
                }
              );
            }}
          >
            <Link href={`/homes/${modelInfo.modelNumber}`}>
              <span>{modelNameTransformer(modelInfo.modelDescription)}</span>
              <ChevronRightSvg />
            </Link>
          </div>
        )}
      </div>
    </HeroImageStyles>
  );
};

HeroImage.propTypes = {
  heroCTA: PropTypes.shape({
    buttonAndTextColors: PropTypes.any,
    ctaCustomCategory: PropTypes.any,
    ctaHeader: PropTypes.any,
    firstCtaButtonText: PropTypes.any,
  }),
  heroImage: PropTypes.shape({
    altText: PropTypes.any,
    imageAsset: PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
    modelNumber: PropTypes.any,
  }),
  heroIndex: PropTypes.any,
  isMobileLayout: PropTypes.any,
  mobileHeroImage: PropTypes.shape({
    altText: PropTypes.any,
    imageAsset: PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
    modelNumber: PropTypes.any,
  }),
};

export default HeroImage;
