import styled from 'styled-components';
import {
  desktop_breakpoint,
  desktop_container_maxwidth,
  tablet_breakpoint,
  tablet_container_maxwidth,
  mobile_width_percentage,
} from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';
import Colors from 'pubweb-smokey/dist/colors.js';

const colorBlueText = '#1b478d';
const colorGreyText = '#030303';
const colorCircleBorder = '#ababab';
const colorCircleBorderSelected = '#00205d';

export const HowItWorksStyles = styled.section`
  background-color: ${(props: { $backgroundColor: string }) =>
    props.$backgroundColor
      ? props.$backgroundColor
      : Colors.primary.claytonBlue.light};
  position: relative;

  @media only screen and (min-width: ${desktop_breakpoint}px) {
    margin-top: 98px;
  }

  //main title
  h2 {
    text-align: center;
    margin: 32px 0 0;
    font-weight: bold;
    font-size: 26px;
    line-height: 48px;
    letter-spacing: -0.35px;
    color: ${colorBlueText};
    @media (min-width: 992px) {
      margin: 60px 0 0;
    }
    @media only screen and (min-width: ${desktop_breakpoint}px) {
      font-size: 33px;
    }
  }

  .base-constrained-width {
    margin: 0 auto;
  }
  @media only screen and (min-width: ${tablet_breakpoint}px) {
    .content-grid {
      padding: 0 ${(100 - mobile_width_percentage) / 2}%;
    }
  }
  @media only screen and (min-width: ${desktop_breakpoint}px) {
    .base-constrained-width {
      width: ${mobile_width_percentage}%;
      max-width: ${desktop_container_maxwidth}px;
    }
    .content-grid {
      padding: 0;
    }
  }

  .inner {
    margin: 0 auto;
    padding: 0;
    display: grid;

    .nav-mobile {
      width: 100%;
      margin: 18px 0 40px;

      .button-wrapper {
        position: relative;

        .button-middle {
          position: absolute;
          left: 50%;
          transform: translate(-50%, 0);
        }

        .button-middle,
        .button-left,
        .button-right {
          display: inline-flex;
          align-items: center;
          padding: 10px 4px; //size of tabs

          cursor: pointer;
          border-radius: 85px;
          border: solid 0.5px white;

          padding-left: 10px; //between left border, and circle
          padding-right: 6px; //between right text, and right border

          background: #ffffff;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          color: ${colorGreyText};
          text-transform: uppercase;

          .nav-circle {
            font-style: normal;
            font-weight: normal;
            font-size: 10px;
            line-height: 17px;
            margin-right: 11px; //spacing between circle and title
            border: solid 0.5px ${colorCircleBorder};
            border-radius: 50%;
            height: 18px;
            width: 18px;
            min-width: 18px;
          }
          .nav-text {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          &.active {
            background: ${colorBlueText}; //selected tab
            color: #ffffff; //tab text color
            .nav-circle {
              background: #ffffff; //selected number
              color: ${Colors.primary.claytonBlue.standard};
              border: solid 1px ${colorCircleBorderSelected}; //selected number
            }
            .nav-text {
              color: #ffffff; //tab text color
              padding-right: 18px;
            }
          }

          &:not(.active) {
            &:hover,
            &:active,
            &:focus {
              background: rgba(255, 255, 255, 0.4);
            }
          }
        }

        .nav-mobile-button-hidden {
          visibility: hidden;
        }
        .button-left {
          border-radius: 0 90px 90px 0;
          float: left;
        }
        .button-middle {
          position: absolute;
          left: 50%;
          z-index: 2;
        }
        .button-right {
          border-radius: 90px 0 0 90px;
          float: right;
        }

        //Size of mobile buttons.
        .button-middle {
          width: fit-content;
        }
        .button-left,
        .button-right {
          width: 23%;
        }
        @media (min-width: ${tablet_container_maxwidth}px) {
          .button-left,
          .button-right {
            width: 28%;
          }
        }
      }
    }

    @media (min-width: ${desktop_breakpoint}px) {
      .nav-mobile {
        display: none;
      }
    }

    > nav {
      display: none;

      //desktop
      @media (min-width: ${desktop_breakpoint}px) {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin: 22px 0 40px;
        align-items: center;
        justify-content: center;

        button {
          display: inline-flex;
          align-items: center;
          background: transparent;
          border: none;

          padding: 14px 20px; //size of tabs
          margin: 0 5px; //spacing between tabs
          outline: none;
          cursor: pointer;
          border-radius: 85px;
          color: ${colorGreyText}; //tab text color

          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          letter-spacing: 1px;
          text-transform: uppercase;

          .nav-circle {
            font-weight: normal;
            margin-right: 8px; //spacing between circle and title
            border: solid 0.5px ${colorCircleBorder};
            border-radius: 50%;
            height: 22px;
            width: 22px;
          }

          &.active {
            background: ${colorBlueText}; //selected tab
            color: #ffffff; //tab text color
            .nav-circle {
              background: #ffffff;
              color: ${Colors.primary.claytonBlue.standard};
              border: solid 1px ${colorCircleBorderSelected};
            }
          }

          &:not(.active) {
            &:hover,
            &:active,
            &:focus {
              background: rgba(255, 255, 255, 0.4);
            }
          }

          &:first-child {
            margin-left: 0;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }

      @media (min-width: 1127px) {
        justify-content: space-around;
      }
    }
  }

  //mobile
  .content-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    padding-bottom: 70px;

    .left {
      grid-area: 2 / 1;
      padding-top: 30px;
      text-align: center;

      a {
        text-decoration: none;
        color: ${colorBlueText};
      }

      .card-desc {
        padding: 14px 25px 25px 25px;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 27px;
        color: ${colorBlueText};
      }

      .card-button {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 1px;
        text-transform: uppercase;
        padding-bottom: 1.5rem;
      }

      .card-headline {
        display: inline-flex;
        align-items: center;
        .card-circle {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          margin-right: 11px; //spacing between circle and title
          border: solid 0.5px ${colorBlueText};
          border-radius: 50%;
          height: 22px;
          width: 22px;
          text-align: center;
        }
        .card-title {
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          letter-spacing: -0.35px;
          color: ${colorBlueText};
        }
      }
    }

    .right {
      grid-area: 1 / 1;

      .right-grid {
        display: grid;
        grid-template-columns: 18px auto 18px;

        .arrow-left {
          grid-area: 1 / 1;
          padding-top: 110px;
        }

        .right-image {
          grid-area: 1 / 2;
          width: 100%;
          img {
            max-width: 100%;
            display: block;
            height: auto;
          }
        }

        .arrow-right {
          grid-area: 1 / 3;
          padding-top: 110px;
        }

        .arrow-hidden {
          visibility: hidden;
        }
      }
    }
  }

  //desktop
  @media screen and (min-width: ${desktop_breakpoint}px) {
    .content-grid {
      display: grid;
      grid-template-columns: 0.9fr 1fr;
      grid-template-rows: 1fr;
      min-height: 400px;

      .left {
        grid-area: 1 / 1;
        padding-top: 0;
        padding-left: 20px;
        text-align: left;

        .card-desc {
          padding: 11px 70px 30px 0;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 29px;
          text-align: left;
          color: ${colorBlueText};
        }

        .card-button-container {
          display: flex;
          flex-direction: row;
          gap: 1.25rem;
        }

        .card-button {
          padding-left: 0;
          text-transform: uppercase;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 14px;
          letter-spacing: 1px;
        }
      }

      .right {
        grid-area: 1 / 2;
        .arrow-left,
        .arrow-right {
          visibility: hidden;
        }
      }
    }
  }

  @media only screen and (min-width: 1127px) {
    .content-grid {
      .left {
        padding-top: 65px;
      }
    }
  }
`;
