import React, {
  useState,
  useLayoutEffect,
  ReactNode,
  MouseEventHandler,
} from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import Image from 'next/image';
import { useSwipeable } from 'react-swipeable';
import { HowItWorksStyles } from './HowItWorks.styled';
import ChevronLeftArrow from './images/arrow_left.svg';
import ChevronRightArrow from './images/arrow_right.svg';
import { pushGTMEvent } from 'pubweb-smokey/dist/utils/analytics';
import { isUrlInternal, formatHttpsUrl } from '@utils/utils';

type Props = {
  snippetSectionHeader: string;
  snippets: Snippet[];
  backgroundColor: string;
};

type Snippet = {
  snippetHeader: string;
  snippetLink: string;
  snippetText: string;
  snippetLinkText: string;
  snippetIcon: {
    description: string;
    file: {
      url: string;
      details: {
        image: {
          width: number;
          height: number;
        };
      };
    };
  };
};

const HowItWorks = ({
  snippetSectionHeader,
  snippets,
  backgroundColor,
}: Props): JSX.Element => {
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [currentTab, setCurrentTab] = useState(0);

  const faqLinks = [
    '/faq/manufactured-home-features/',
    '/faq/manufactured-home-buying/',
    '/faq/manufactured-home-financing/',
    '/faq/manufactured-home-building/',
    '/faq/manufactured-home-delivery/',
    '/faq/manufactured-homeownership/',
  ];

  const gtmDictionary = [
    {
      label: 'hiw_find_home',
      eventId: {
        button: 'ch.hiwFindHomeCTAPillClick',
        text: 'ch.hiwFindHomeLearnMoreClick',
      },
    },
    {
      label: 'hiw_find_land',
      eventId: {
        button: 'ch.hiwFindLandCTAPillClick',
        text: 'ch.hiwFindLandLearnMoreClick',
      },
    },
    {
      label: 'hiw_financing',
      eventId: {
        button: 'ch.hiwFinancingCTAPillClick',
        text: 'ch.hiwFinancingLearnMoreClick',
      },
    },
    {
      label: 'hiw_building',
      eventId: {
        button: 'ch.hiwBuildItCTAPillClick',
        text: 'ch.hiwBuildItLearnMoreClick',
      },
    },
    {
      label: 'hiw_delivery',
      eventId: {
        button: 'ch.hiwDeliveredCTAPillClick',
        text: 'ch.hiwDeliveredLearnMoreClick',
      },
    },
    {
      label: 'hiw_completion',
      eventId: {
        button: 'ch.hiwConstructionCTAPillClick',
        text: 'ch.hiwConstructionLearnMoreClick',
      },
    },
  ];

  const handleGTM = (isButton = false) => {
    if (gtmDictionary[currentTab]) {
      const eventId = isButton
        ? `${gtmDictionary[currentTab].eventId.button}`
        : `${gtmDictionary[currentTab].eventId.text}`;

      pushGTMEvent(eventId, `${gtmDictionary[currentTab].label}`, {
        location: 'Homepage',
        category: 'home_education',
        action: isButton ? 'button' : 'text',
        label: eventId,
      });
    }
  };

  //Listen for step-button clicks, and swipes.  Then logs to GTM.
  useLayoutEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    } else {
      handleGTM(true);
    }
  }, [currentTab]);

  const handleSwipedLeft = () => {
    if (currentTab >= 0 && currentTab < snippets.length - 1) {
      setCurrentTab(currentTab + 1);
    }
  };

  const handleSwipedRight = () => {
    if (currentTab > 0) {
      setCurrentTab(currentTab - 1);
    }
  };

  const swipeableHandlers = useSwipeable({
    onSwipedLeft: () => handleSwipedLeft(),
    onSwipedRight: () => handleSwipedRight(),
    trackMouse: true,
  });

  type LinkWrapperProps = {
    to: string;
    children: ReactNode;
    onClick?: MouseEventHandler<HTMLAnchorElement>;
  };

  const LinkWrapper = ({ to, onClick, children }: LinkWrapperProps) => {
    return isUrlInternal(to) ? (
      <Link href={to} onClick={onClick}>
        {children}
      </Link>
    ) : (
      <a href={to} onClick={onClick}>
        {children}
      </a>
    );
  };

  LinkWrapper.propTypes = {
    to: PropTypes.string,
    onClick: PropTypes.func,
    children: PropTypes.any,
  };

  const drawContentParagraph = (currentTab: number) => {
    const buttonUrl = snippets[currentTab].snippetLink;

    return (
      <section className="content-grid">
        <div className="left">
          <LinkWrapper to={buttonUrl} onClick={() => handleGTM()}>
            <div className="card-headline">
              <span className="card-circle">{currentTab + 1}</span>
              <span className="card-title">
                {snippets[currentTab].snippetHeader}
              </span>
            </div>
          </LinkWrapper>
          <div className="card-desc">{snippets[currentTab].snippetText}</div>
          <div className="card-button-container">
            <div className="card-button">
              <LinkWrapper to={buttonUrl} onClick={() => handleGTM()}>
                {snippets[currentTab].snippetLinkText}
              </LinkWrapper>
            </div>
            <div className="card-button">
              <LinkWrapper to={faqLinks[currentTab]}>View FAQs</LinkWrapper>
            </div>
          </div>
        </div>
        <div className="right">
          <div className="right-grid">
            <div className="arrow-left">
              <ChevronLeftArrow
                className={`${currentTab === 0 ? 'arrow-hidden' : ''}`}
              />
            </div>
            <div className="right-image">
              <LinkWrapper to={buttonUrl} onClick={() => handleGTM()}>
                <Image
                  alt={snippets[currentTab].snippetIcon?.description}
                  src={formatHttpsUrl(
                    snippets[currentTab].snippetIcon?.file?.url + '?fm=webp'
                  )}
                  width={701}
                  height={424}
                />
              </LinkWrapper>
            </div>
            <div className="arrow-right">
              <ChevronRightArrow
                className={`${
                  currentTab === snippets.length - 1 ? 'arrow-hidden' : ''
                }`}
              />
            </div>
          </div>
        </div>
      </section>
    );
  };

  if (!snippets) {
    return <>no content</>;
  }

  return (
    <HowItWorksStyles $backgroundColor={backgroundColor}>
      <div className="base-constrained-width">
        <section className="inner" {...swipeableHandlers}>
          <h2>{snippetSectionHeader}</h2>

          <nav>
            {snippets.map(({ snippetHeader }, i) => (
              <button
                onClick={() => setCurrentTab(i)}
                className={`${currentTab === i ? 'active' : ''}`}
                key={snippetHeader}
              >
                <span className="nav-circle">{i + 1}</span>
                <span>{snippetHeader}</span>
              </button>
            ))}
          </nav>

          <div className="nav-mobile">
            <div className="button-wrapper">
              {currentTab === 0 ? (
                ''
              ) : (
                <button
                  className="button-left"
                  onClick={() => setCurrentTab(currentTab - 1)}
                >
                  <span className="nav-circle">{currentTab}</span>
                  <span className="nav-text">
                    {snippets[currentTab - 1].snippetHeader}
                  </span>
                </button>
              )}

              <button className="button-middle active">
                <span className="nav-circle">{currentTab + 1}</span>
                <span className="nav-text">
                  {snippets[currentTab].snippetHeader}
                </span>
              </button>

              {currentTab === snippets.length - 1 ? (
                ''
              ) : (
                <button
                  className="button-right"
                  onClick={() => setCurrentTab(currentTab + 1)}
                >
                  <span className="nav-circle">{currentTab + 2}</span>
                  <span className="nav-text">
                    {snippets[currentTab + 1].snippetHeader}
                  </span>
                </button>
              )}
            </div>
          </div>

          {drawContentParagraph(currentTab)}
        </section>
      </div>
    </HowItWorksStyles>
  );
};

export default HowItWorks;
