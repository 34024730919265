import styled from 'styled-components';
import Colors from 'pubweb-smokey/dist/colors';
import { desktop_breakpoint_extended } from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';

export const FeaturedHomeStyles = styled.div`
  width: 100%;
  margin: 40px 0;

  .bottom-controls {
    margin-top: 10px;
  }

  .loading-indicator {
    height: 270px;
  }

  .feature-card {
    margin: 5px;
  }

  .headline {
    text-align: center;
    margin-bottom: 15px;
    font-size: 18px;
  }

  .headline-left {
    text-align: center;
    margin-left: 16px;
    margin-bottom: 15px;
    font-size: 18px;
  }

  .headline-link {
    text-decoration: none;
  }

  .featured-location {
    text-align: center;
    margin: 0 auto 20px;
    color: ${Colors.primary.black.standard};
    font-size: 18px;

    a {
      width: ${(props) => (props.$isZipError ? '100px' : 'none')};
      display: inline-flex;

      p {
        width: 100%;
        border-bottom: 1px solid rgb(0, 117, 201);
      }
    }
  }
  .featured-location-wrap {
    max-width: 310px;
    margin: 0 auto;
    .modal-window {
      // Make the modal appear a little higher when it opens.
      top: -120px;
    }
  }

  .no-featured-homes {
    text-align: center;
    padding: 10px;
  }

  .featured-buttons {
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: 40px;
    flex-wrap: wrap;
    gap: 15px;
    button {
      margin: 0 auto;
    }
  }

  @media only screen and (min-width: 450px) {
    width: 86%;
    margin: 40px auto;
  }
  @media only screen and (min-width: 768px) {
    .headline {
      font-size: 28px;
    }
    .featured-location {
      margin: 0 auto 40px;
    }

    .headline-left {
      text-align: left;
      font-size: 28px;
    }
  }

  @media screen and (min-width: ${desktop_breakpoint_extended}px) {
    margin-top: 0;
  }
`;
