export const allPriceChoicesMin = [
  { value: 25000, text: '$25,000' },
  { value: 50000, text: '$50,000' },
  { value: 75000, text: '$75,000' },
  { value: 100000, text: '$100,000' },
  { value: 125000, text: '$125,000' },
  { value: 150000, text: '$150,000' },
  { value: 175000, text: '$175,000' },
];

export const allPriceChoicesMax = [
  { value: 50000, text: '$50,000' },
  { value: 75000, text: '$75,000' },
  { value: 100000, text: '$100,000' },
  { value: 125000, text: '$125,000' },
  { value: 150000, text: '$150,000' },
  { value: 175000, text: '$175,000' },
  { value: 200000, text: '$200,000' },
  { value: 225000, text: '$225,000' },
  { value: 250000, text: '$250,000' },
  { value: 275000, text: '$275,000' },
  { value: 300000, text: '$300,000' },
  { value: 325000, text: '$325,000' },
  { value: 350000, text: '$350,000' },
  { value: 375000, text: '$375,000' },
  { value: 800000, text: '$400,000+' },
];

export const bedItems = [
  { value: 1, text: '1+' },
  { value: 2, text: '2+' },
  { value: 3, text: '3+' },
  { value: 4, text: '4+' },
  { value: 5, text: '5+' },
];

export const bathItems = [
  { value: 1, text: '1+' },
  { value: 2, text: '2+' },
  { value: 3, text: '3+' },
];
