import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';
import Modal from 'pubweb-smokey/dist/components/Modal/Modal';
import Button from '@components/Shared/Buttons/Button';
import CubeOpen from 'pubweb-smokey/dist/images/svg/iconography-16x16/cube-open.svg';

import Colors from 'pubweb-smokey/dist/colors';

import { Carousel } from 'react-responsive-carousel';

import { SlideshowVendorStyles } from './SlideshowVendorStyles.styled';
import { SlideshowStyles } from './Slideshow.styled';

import { useHorizontalSwipe } from '@utils/hooks';
import { formatHttpsUrl } from '@utils/utils';
import { formatVirtTourRef } from '@utils/virtualTourUtils';

const Slideshow = ({
  imageArray,
  virtualTourReference,
  isDesktop,
  viewportWidth,
  transitionTime = 300,
  ...props
}) => {
  const [show3DTourModal, setShow3DTourModal] = useState(false);

  const showArrows = imageArray && imageArray.length > 1;

  // On mobile (touch device), the carousel sometimes moves up/down vertically when swiping.
  // Added this code to detect horizontal swipe and prevent the up/down scrolling.
  useHorizontalSwipe('slideshow-carousel');

  return (
    <SlideshowVendorStyles className="slideshow">
      <SlideshowStyles $showArrows={showArrows} $viewportWidth={viewportWidth}>
        {show3DTourModal && virtualTourReference && (
          <Modal onClose={() => setShow3DTourModal(false)}>
            <iframe
              title="Matterport 3D Tour"
              className="gtm-matterport-play"
              scrolling="no"
              frameBorder="0"
              allowFullScreen
              allow="xr-spatial-tracking"
              src={formatVirtTourRef(virtualTourReference)}
              width="100%"
              height="100%"
            />
          </Modal>
        )}

        <div id="slideshow-carousel">
          {!!imageArray?.length && (
            <Carousel
              showArrows={showArrows}
              showIndicators={isDesktop && true}
              showStatus={false}
              transitionTime={transitionTime}
              thumbWidth={130}
              infiniteLoop={true}
              renderThumbs={(children) =>
                children.map((item, i) => {
                  return (
                    <Image
                      key={i}
                      src={item.props.src}
                      alt={item.props.alt}
                      width={130}
                      height={80}
                    />
                  );
                })
              }
              {...props}
            >
              {imageArray.map((image, i) => {
                if (image.reference) {
                  return (
                    <Image
                      key={i}
                      src={
                        formatHttpsUrl(image.reference) +
                        (viewportWidth
                          ? '?fm=webp&width=' + viewportWidth
                          : '?fm=webp')
                      }
                      alt={
                        image.roomDescription
                          ? image.roomDescription
                          : 'Detail Image'
                      }
                      width={1100}
                      height={730}
                    />
                  );
                } else {
                  return image;
                }
              })}
            </Carousel>
          )}
        </div>

        {virtualTourReference && (
          <div className="tour-button-container">
            <Button
              automationId="3d-tour-click"
              className="take-3d-tour"
              primaryColor={Colors.primary.black}
              isCompact={true}
              buttonStyle="shadow"
              onClick={() => setShow3DTourModal(true)}
            >
              <CubeOpen /> Take 3D Tour
            </Button>
          </div>
        )}
      </SlideshowStyles>
    </SlideshowVendorStyles>
  );
};

Slideshow.propTypes = {
  imageArray: PropTypes.array,
  isDesktop: PropTypes.bool,
  transitionTime: PropTypes.number,
  viewportWidth: PropTypes.any,
  virtualTourReference: PropTypes.any,
};

export default Slideshow;
